<template>
    <recess-card variant="variant1" class="px-5 my-3">
        <div class="row">
            <!-- Subtitle -->
            <div class="col-12">
                <subtitle-text :subtitle="PROVIDER.Portfolio.CourseEdit.PlanningTab.Title"
                    qa-class="qa-edit-planning"
                />
            </div>
        </div>

        <div class="row">
            <recess-multi-select
                :selected-options="selectedFilters.startMomentStatus"
                :options="startMomentStatusOptions"
                :single-select="true"
                class="col-2 qa-startmoment-status-select"
                @input="(newValue) => changeStatus(newValue)"
            />
            <recess-date-picker
                v-if="!isOnCourseCreatePage"
                v-model="selectedFilters.startDate"
                :default-value="selectedFilters.startDate"
                :placeholder="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.AccordionTitle"
                :error-message="isStartDateInThePast()"
                date-picker-input-custom-width="170px"
                class="qa-startmoment-date-picker"
                @input="(newValue) => changeStartDate(newValue)"
            />
            <recess-multi-select
                v-if="!isOnCourseCreatePage"
                :selected-options="selectedFilters.city"
                :options="cityOptions"
                :select-options-text="cityOptions && cityOptions[0].displayText"
                :search-input="true"
                :single-select="true"
                class="col-3 qa-startmoment-location-select"
                @input="(newValue) => changeLocation(newValue)"
            />

            <!-- Start moment accordion list -->
            <div class="col-12 mt-4" v-if="!isOnCourseCreatePage">
                <div>
                    <template v-if="getPlanningData">
                        <!-- Start moment accordion -->
                        <recess-table-flex
                            class="qa-startmoments-table u-overflow-inherit" 
                            :pagination-options="setPaginationOptions" 
                            :table-options="setTableOptions" 
                            @getData="getList"
                        >
                            <recess-table-flex-body>
                                <recess-table-flex-row  v-for="(startMomentItem, startMomentIndex) in planningAndInvoicesData.learningMethod.planning.startMoments" 
                                    :key="`startMomentItem-${startMomentIndex}`" 
                                >
                                    <recess-table-flex-cell class="container-fluid">
                                        <recess-accordion
                                            ref="accordions"
                                            variant="variant-1"
                                            :class="[`s-height-auto col-12 qa-start-moment-accordion-data-${startMomentIndex}`, { 'has-inner-error': startMomentItem.hasErrors }]"
                                            :active="startMomentItem.isActive"
                                            @toggled="(newValue) => toggleAccordion(startMomentIndex, startMomentItem, newValue)"
                                        >
                                        <template v-slot:accordionLeftCustomActions>
                                            <recess-toggle-input
                                                v-if="!isOnCourseCreatePage"
                                                :value="!startMomentItem.inactive"
                                                name="inactive-start-moment"
                                                :class="`qa-inactive-start-moment-${startMomentIndex}`"
                                                size="small"
                                                @input="(newValue) => toggleStartmomentState(startMomentIndex, !newValue)"
                                            />
                                        </template>

                                        <template slot="accordion-title">
                                            <div class="py-2 d-flex align-items-center justify-content-start">
                                                <div>
                                                    <h5>
                                                        {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.AccordionTitle }}
                                                        {{ startMomentItem.startDate }}
                                                    </h5>

                                                    <div class="d-flex">
                                                        <div v-if="startMomentItem.externalId"
                                                            class="mr-4 qa-start-moment-id-column"
                                                        >
                                                            {{ startMomentItem.externalId }}
                                                        </div>

                                                        <div v-if="startMomentItem.streetName"
                                                            class="mr-4 u-fw-semi-bold qa-start-moment-location-column"
                                                        >
                                                            {{ startMomentItem.streetName }}
                                                        </div>

                                                        <div v-if="startMomentItem.houseNumber"
                                                            class="mr-4 u-fw-semi-bold qa-start-moment-location-column"
                                                        >
                                                            {{ startMomentItem.houseNumber }}
                                                        </div>

                                                        <div v-if="startMomentItem.postcode"
                                                            class="mr-4 u-fw-semi-bold qa-start-moment-location-column"
                                                        >
                                                            {{ startMomentItem.postcode }}
                                                        </div>

                                                        <div v-if="startMomentItem.city"
                                                            class="mr-4 u-fw-semi-bold qa-start-moment-location-column"
                                                        >
                                                            {{ startMomentItem.city }}
                                                        </div>

                                                        <div v-if="startMomentItem.minParticipants || startMomentItem.maxParticipants" class="mr-4 u-fw-semi-bold qa-start-moment-location-column">
                                                            {{ startMomentItem.minParticipants || 'Min' }}-{{ startMomentItem.maxParticipants || 'max' }}
                                                            {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Participants }}
                                                        </div>

                                                        <div v-if="!isOnCourseCreatePage"
                                                            class="mr-4 qa-start-moment-type-column"
                                                        >
                                                            {{ getStartMomentType(startMomentItem.type) }}
                                                        </div>
                                                        <div class="mr-4 u-fw-semi-bold qa-start-moment-location-column">
                                                            {{ setEventsAmount(startMomentItem, startMomentIndex) }}
                                                            {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Events }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>

                                        <template v-slot:accordionRightCustomActions>
                                            <div
                                                class="d-flex mr-4 aling-items-center"
                                            >
                                                <recess-checkbox-input
                                                    :value="startMomentItem.startGuaranteed"
                                                    name="test"
                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Definitive"
                                                    :default-checked="startMomentItem.startGuaranteed"
                                                    variant="secondary"
                                                    :class="`u-fw-semi-bold qa-start-moment-startGuaranteed-checkbox-${startMomentIndex}`"
                                                    :disabled="false"
                                                    @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'startGuaranteed', value }) }"
                                                />
                                            </div>

                                            <recess-tooltip class="mr-4">
                                                <span :class="`s-duplicate-top c-icon c-icon__copy qa-duplicate-startmoment-${startMomentIndex}`"
                                                    @click="triggerDuplicateStartmoment(startMomentIndex)"
                                                ></span>

                                                <template v-slot:content>
                                                    <p>{{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.DuplicateIcon.Tooltip }}</p>
                                                </template>
                                            </recess-tooltip>

                                            <span :class="`mr-4 c-icon c-icon__bin qa-remove-start-moment-button-${startMomentIndex}`"
                                                @click="removeStartMomentItem(startMomentIndex, startMomentItem.id)"
                                            ></span>
                                        </template>

                                        <div slot="accordion-content" class="d-flex justify-content-between align-items-center">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <h5 class="col-12 mt-2 mb-3">
                                                        {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Title }}
                                                    </h5>

                                                    <!-- Street Name -->
                                                    <recess-input
                                                        :value="startMomentItem.streetName"
                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.StreetName.Label"
                                                        :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.StreetName.Placeholder"
                                                        :class="`col-4 qa-start-moment-streetName-${startMomentIndex}`"
                                                        @input="(newValue) => onStartMomentStreetNameTouch(startMomentIndex, newValue)"
                                                    />

                                                    <!-- House Number -->
                                                    <recess-input
                                                        :value="startMomentItem.houseNumber"
                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.HouseNumber.Label"
                                                        :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.HouseNumber.Placeholder"
                                                        :class="`col-4 qa-start-moment-houseNumber-${startMomentIndex}`"
                                                        @input="(newValue) => onStartMomentHouseNumberTouch(startMomentIndex, newValue)"
                                                    />

                                                    <!-- min Participants -->
                                                    <recess-multi-select
                                                        :selected-options="getMinParticipants(startMomentItem)"
                                                        :options="participantsOptions"
                                                        :single-select="true"
                                                        :search-input="true"
                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.MinParticipants.Label"
                                                        :placeholder="`${startMomentItem.minParticipants}` || '-'"
                                                        :class="`col-2 qa-start-moment-min-participant-${startMomentIndex}`"
                                                        :error-message="minParticipantError(startMomentIndex)"
                                                        @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'minParticipants', value }) }"
                                                    />

                                                    <!-- max Participants -->
                                                    <recess-multi-select
                                                        :selected-options="getMaxParticipants(startMomentItem)"
                                                        :options="participantsOptions"
                                                        :single-select="true"
                                                        :search-input="true"
                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.MaxParticipants.Label"
                                                        :placeholder="`${startMomentItem.maxParticipants}` || '-'"
                                                        :class="`col-2 qa-start-moment-max-participant-${startMomentIndex}`"
                                                        :error-message="maxParticipantError(startMomentIndex)"
                                                        @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'maxParticipants', value }) }"
                                                    />
                                                </div>

                                                <div class="row">
                                                    <!-- Postcode -->
                                                    <recess-input
                                                        :value="startMomentItem.postcode"
                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Postcode.Label"
                                                        :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Postcode.Placeholder"
                                                        :class="`col-4 qa-start-moment-postcode-${startMomentIndex}`"
                                                        @input="(newValue) => onStartMomentPostcodeTouch(startMomentIndex, newValue)"
                                                    />


                                                    <!-- City -->
                                                    <recess-input
                                                        :value="startMomentItem.city"
                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.City.Label"
                                                        :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.City.Placeholder"
                                                        :error-message="startMomentCityError(startMomentIndex)"
                                                        :class="`col-4 qa-start-moment-city-${startMomentIndex}`"
                                                        @input="(newValue) => onStartMomentCityTouch(startMomentIndex, newValue)"
                                                    />

                                                    <!-- ID -->
                                                    <recess-input
                                                        :value="startMomentItem.externalId"
                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ExternalId.Label"
                                                        :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ExternalId.Placeholder"
                                                        :class="`col-4 qa-start-moment-id-${startMomentIndex}`"
                                                        :error-message="startMomentExternalIdError(startMomentIndex)"
                                                        @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'externalId', value }) }"
                                                    >
                                                        <template slot="customIcon">
                                                            <recess-tooltip>
                                                                <template slot="content">
                                                                    <div>{{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ExternalId.Tooltip }}</div>
                                                                </template>
                                                            </recess-tooltip>
                                                        </template>
                                                    </recess-input>
                                                </div>

                                                <div class="row">
                                                    <div class="col-4 d-flex">
                                                        <recess-date-picker
                                                            :value="startMomentItem.startDate"
                                                            :default-value="startMomentItem.startDate"
                                                            :class="`w-60 qa-start-moment-start-date-${startMomentIndex}`"
                                                            :label-text="`${PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Date.Label}*`"
                                                            :error-message="startMomentFullDateError(startMomentIndex)"
                                                            date-picker-input-custom-width="170px"
                                                            @input="(newValue) => onStartMomentDateChanged(startMomentIndex, newValue)"
                                                        />

                                                        <!-- Type -->
                                                        <recess-multi-select
                                                            :selected-options="startMomentItem.type"
                                                            :options="startMomentTypes"
                                                            :single-select="true"
                                                            :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Type.Label"
                                                            :select-option-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Type.SelectText"
                                                            :class="`w-40 qa-start-moment-type-${startMomentIndex}`"
                                                            @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'type', value }) }"
                                                        />
                                                    </div>
                                                </div>

                                                <div v-if="enableCutOffDate"
                                                    class="row"
                                                >
                                                    <div class="col-6">
                                                        <recess-date-picker
                                                            :value="startMomentItem.cutOffDate"
                                                            :default-value="startMomentItem.cutOffDate"
                                                            :class="`qa-start-moment-cut-off-date-${startMomentIndex}`"
                                                            :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.CutOffDate.Label"
                                                            date-picker-input-custom-width="170px"
                                                            :error-message="startMomentCutOffDateError(startMomentIndex)"
                                                            @input="(newValue) => triggerCutOffDate(startMomentIndex, newValue)"
                                                        >
                                                            <div slot="customIcon">
                                                                <recess-tooltip class="ml-1">
                                                                    <div slot="content">
                                                                        <div>
                                                                            {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.CutOffDate.Tooltip }}
                                                                        </div>
                                                                    </div>
                                                                </recess-tooltip>
                                                            </div>
                                                        </recess-date-picker>
                                                    </div>
                                                </div>

                                                <!-- Modules -->

                                                <div class="row mt-5">
                                                    <div v-for="(moduleItem, moduleIndex) in planningAndInvoicesData.learningMethod.planning.startMoments[startMomentIndex].modules"
                                                        :key="`moduleItem-${moduleIndex}`"
                                                        class="col-12 mb-2"
                                                    >
                                                        <div class="row">
                                                            <div class="col-12 d-flex align-items-start justify-content-between">
                                                                <div class="row my-0 w-100">
                                                                    <div class="col-4">
                                                                        <!-- Name -->
                                                                        <recess-input
                                                                            :value="moduleItem.name"
                                                                            :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.Name.Label"
                                                                            :error-message="moduleNameError(startMomentIndex, moduleIndex)"
                                                                            :class="`qa-module-name-S${startMomentIndex}-M${moduleIndex}`"
                                                                            @input="(newValue) => onModuleNameInput(startMomentIndex, moduleIndex, newValue)"
                                                                        />
                                                                    </div>

                                                                    <div v-if="!isOnCourseCreatePage"
                                                                        class="col-4"
                                                                    >
                                                                        <recess-input
                                                                            :value="moduleItem.city"
                                                                            :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.City.Label"
                                                                            :class="`qa-module-city-S${startMomentIndex}-M${moduleIndex}`"
                                                                            @input="(value) => { $emit('setStartMoments', { action: 'setModuleField', startMomentIndex, moduleIndex, field: 'city', value }) }"
                                                                        />
                                                                    </div>

                                                                    <div v-else
                                                                        class="col-4"
                                                                    >
                                                                        <!-- Day of week -->
                                                                        <recess-multi-select
                                                                            :selected-options="moduleItem.dayOfWeek"
                                                                            :options="daysOfWeek"
                                                                            :single-select="true"
                                                                            :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.DayOfWeek.Label"
                                                                            :select-options-text="daysOfWeek && daysOfWeek[0].displayText"
                                                                            :class="`qa-module-day-S${startMomentIndex}-M${moduleIndex}`"
                                                                            @input="(value) => { $emit('setStartMoments', { action: 'setModuleField', startMomentIndex, moduleIndex, field: 'dayOfWeek', value }) }"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div class="d-flex align-items-end s-delete-icon__input-align">
                                                                    <span
                                                                        :class="`c-icon c-icon__bin qa-remove-module-button-S${startMomentIndex}-M${moduleIndex}`"
                                                                        @click="removeModule(startMomentIndex, moduleIndex)"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- render events -->
                                                        <div class="row justify-content-between">
                                                            <div v-for="(eventItem, eventIndex) in moduleItem.events"
                                                                :key="`eventItem-${eventIndex}`"
                                                                class="mt-4 col-12 w-100 d-flex align-items-start"
                                                            >
                                                                <!-- Date & DayOfWeek-->
                                                                <recess-date-picker
                                                                    :value="eventItem.eventDate"
                                                                    :default-value="eventItem.eventDate"
                                                                    :class="`mr-3 qa-event-date-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                    :label-text="`${PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.EventDate.Label}*`"
                                                                    :error-message="eventDateError(startMomentIndex, moduleIndex, eventIndex)"
                                                                    date-picker-input-custom-width="170px"
                                                                    @input="(newValue) => onEventDateChanged(startMomentIndex, moduleIndex, eventIndex, newValue)"
                                                                />
                                                                <!-- StartTime -->
                                                                <recess-input
                                                                    :value="eventItem.startTime"
                                                                    type="time"
                                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.StartTime.Label"
                                                                    :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.StartTime.Placeholder"
                                                                    :class="`mr-3 qa-event-start-time-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                    @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'startTime', value }) }"
                                                                />

                                                                <!-- EndTime -->
                                                                <recess-input
                                                                    :value="eventItem.endTime"
                                                                    type="time"
                                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.EndTime.Label"
                                                                    :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.EndTime.Placeholder"
                                                                    :class="`mr-3 qa-event-end-time-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                    @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'endTime', value }) }"
                                                                />

                                                                <!-- Location -->
                                                                <recess-input
                                                                    :value="eventItem.city"
                                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.City.Label"
                                                                    :class="`mr-3 qa-event-city-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                    @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'city', value }) }"
                                                                />

                                                                <!-- Buliding -->
                                                                <recess-input
                                                                    :value="eventItem.building"
                                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.Building.Label"
                                                                    :class="`mr-3 qa-event-building-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                    @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'building', value }) }"
                                                                />

                                                                <!-- Room -->
                                                                <recess-input
                                                                    :value="eventItem.room"
                                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.Room.Label"
                                                                    :class="`qa-event-room-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                    @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'room', value }) }"
                                                                />

                                                                <!-- remove event button -->
                                                                <div class="d-flex align-items-end s-delete-icon__input-align ml-5">
                                                                    <span
                                                                        :class="`mr-4 s-duplicate-top c-icon c-icon__copy qa-duplicate-event-button-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                        @click="duplicateEvent(startMomentIndex, moduleIndex, eventItem)"
                                                                    ></span>

                                                                    <span
                                                                        :class="`c-icon c-icon__bin qa-remove-event-button-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                        @click="removeEvent(startMomentIndex, moduleIndex, eventIndex)"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row my-0">
                                                            <div class="col-12 d-flex justify-content-end">
                                                                <!-- add event button -->
                                                                <recess-button
                                                                    :title="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.AddEventItemButton"
                                                                    icon="add"
                                                                    variant="variant4"
                                                                    :class="`qa-add-event-button-${moduleIndex}`"
                                                                    @click.native.prevent="addEventItem(startMomentIndex, moduleIndex)"
                                                                />
                                                            </div>
                                                        </div>

                                                        <recess-divider show-line class="mt-2" />
                                                    </div>

                                                    <div class="col-12 d-flex justify-content-end">
                                                        <recess-button
                                                            :title="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.AddModuleItemButton"
                                                            icon="add"
                                                            variant="variant4"
                                                            :class="`qa-add-module-button1-S${startMomentIndex}`"
                                                            @click.native.prevent="addModule(startMomentIndex)"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </recess-accordion>
                                    </recess-table-flex-cell>
                                </recess-table-flex-row>
                            </recess-table-flex-body>
                        </recess-table-flex>
                    </template>
                    <!-- If no future start moments exist or e-learning course, show message -->
                    <template v-else>
                        <div class="w-100 d-flex justify-content-center align-items-center my-2">
                            <p>
                                {{ showNoCoursePlaceholderText }}
                            </p>
                        </div>
                    </template>
                </div>

            </div>
            <div class="col-12 mt-4" v-else>
                <h5>{{ PROVIDER.Portfolio.CourseEdit.PlanningTab.StartmomentTitle }}</h5>
                <div>
                    <template v-if="getPlanningData">
                        <!-- Start moment accordion -->
                        <div v-for="(startMomentItem, startMomentIndex) in planningAndInvoicesData.learningMethod.planning.startMoments"
                            :key="`startMomentItem-${startMomentIndex}`"
                            class="container-fluid"
                        >
                            <div class="row">
                                <recess-accordion
                                    ref="accordions"
                                    variant="variant-1"
                                    :class="[`s-height-auto col-12 qa-start-moment-accordion-data-${startMomentIndex}`, { 'has-inner-error': startMomentItem.hasErrors }]"
                                    :active="startMomentItem.isActive"
                                    @toggled="(newValue) => toggleAccordion(startMomentIndex, startMomentItem, newValue)"
                                >
                                    <template v-slot:accordionLeftCustomActions>
                                        <recess-toggle-input
                                            v-if="!isOnCourseCreatePage"
                                            :value="!startMomentItem.inactive"
                                            name="inactive-start-moment"
                                            :class="`qa-inactive-start-moment-${startMomentIndex}`"
                                            size="small"
                                            @input="(newValue) => toggleStartmomentState(startMomentIndex, !newValue)"
                                        />
                                    </template>

                                    <template slot="accordion-title">
                                        <div class="py-2 d-flex align-items-center justify-content-start">
                                            <div>
                                                <h5>
                                                    {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.AccordionTitle }}
                                                    {{ startMomentItem.startDate }}
                                                </h5>

                                                <div class="d-flex">
                                                    <div v-if="startMomentItem.externalId"
                                                        class="mr-4 qa-start-moment-id-column"
                                                    >
                                                        {{ startMomentItem.externalId }}
                                                    </div>

                                                    <div v-if="startMomentItem.streetName"
                                                        class="mr-4 u-fw-semi-bold qa-start-moment-location-column"
                                                    >
                                                        {{ startMomentItem.streetName }}
                                                    </div>

                                                     <div v-if="startMomentItem.houseNumber"
                                                        class="mr-4 u-fw-semi-bold qa-start-moment-location-column"
                                                    >
                                                        {{ startMomentItem.houseNumber }}
                                                    </div>

                                                    <div v-if="startMomentItem.postcode"
                                                        class="mr-4 u-fw-semi-bold qa-start-moment-location-column"
                                                    >
                                                        {{ startMomentItem.postcode }}
                                                    </div>

                                                    <div v-if="startMomentItem.city"
                                                        class="mr-4 u-fw-semi-bold qa-start-moment-location-column"
                                                    >
                                                        {{ startMomentItem.city }}
                                                    </div>

                                                    <div v-if="startMomentItem.minParticipants || startMomentItem.maxParticipants" class="mr-4 u-fw-semi-bold qa-start-moment-location-column">
                                                        {{ startMomentItem.minParticipants || 'Min' }}-{{ startMomentItem.maxParticipants || 'max' }}
                                                        {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Participants }}
                                                    </div>

                                                    <div v-if="!isOnCourseCreatePage"
                                                        class="mr-4 qa-start-moment-type-column"
                                                    >
                                                        {{ getStartMomentType(startMomentItem.type) }}
                                                    </div>
                                                    <div class="mr-4 u-fw-semi-bold qa-start-moment-location-column">
                                                        {{ setEventsAmount(startMomentItem, startMomentIndex) }}
                                                        {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Events }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-slot:accordionRightCustomActions>
                                        <div
                                            class="d-flex mr-4 aling-items-center"
                                        >
                                            <recess-checkbox-input
                                                :value="startMomentItem.startGuaranteed"
                                                name="test"
                                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Definitive"
                                                :default-checked="startMomentItem.startGuaranteed"
                                                variant="secondary"
                                                :class="`u-fw-semi-bold qa-start-moment-startGuaranteed-checkbox-${startMomentIndex}`"
                                                :disabled="false"
                                                @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'startGuaranteed', value }) }"
                                            />
                                        </div>

                                        <recess-tooltip class="mr-4">
                                            <span :class="`s-duplicate-top c-icon c-icon__copy qa-duplicate-startmoment-${startMomentIndex}`"
                                                @click="triggerDuplicateStartmoment(startMomentIndex)"
                                            ></span>

                                            <template v-slot:content>
                                                <p>{{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.DuplicateIcon.Tooltip }}</p>
                                            </template>
                                        </recess-tooltip>

                                        <span :class="`mr-4 c-icon c-icon__bin qa-remove-start-moment-button-${startMomentIndex}`"
                                            @click="removeStartMomentItem(startMomentIndex, startMomentItem.id)"
                                        ></span>
                                    </template>

                                    <div slot="accordion-content" class="d-flex justify-content-between align-items-center">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <h5 class="col-12 mt-2 mb-3">
                                                    {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Title }}
                                                </h5>

                                                <!-- Street Name -->
                                                <recess-input
                                                    :value="startMomentItem.streetName"
                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.StreetName.Label"
                                                    :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.StreetName.Placeholder"
                                                    :class="`col-4 qa-start-moment-streetName-${startMomentIndex}`"
                                                    @input="(newValue) => onStartMomentStreetNameTouch(startMomentIndex, newValue)"
                                                />

                                                <!-- House Number -->
                                                <recess-input
                                                    :value="startMomentItem.houseNumber"
                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.HouseNumber.Label"
                                                    :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.HouseNumber.Placeholder"
                                                    :class="`col-4 qa-start-moment-houseNumber-${startMomentIndex}`"
                                                    @input="(newValue) => onStartMomentHouseNumberTouch(startMomentIndex, newValue)"
                                                />

                                                <!-- min Participants -->
                                                <recess-multi-select
                                                    :selected-options="getMinParticipants(startMomentItem)"
                                                    :options="participantsOptions"
                                                    :single-select="true"
                                                    :search-input="true"
                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.MinParticipants.Label"
                                                    :placeholder="`${startMomentItem.minParticipants}` || '-'"
                                                    :class="`col-2 qa-start-moment-min-participant-${startMomentIndex}`"
                                                    :error-message="minParticipantError(startMomentIndex)"
                                                    @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'minParticipants', value }) }"
                                                />

                                                <!-- max Participants -->
                                                <recess-multi-select
                                                    :selected-options="getMaxParticipants(startMomentItem)"
                                                    :options="participantsOptions"
                                                    :single-select="true"
                                                    :search-input="true"
                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.MaxParticipants.Label"
                                                    :placeholder="`${startMomentItem.maxParticipants}` || '-'"
                                                    :class="`col-2 qa-start-moment-max-participant-${startMomentIndex}`"
                                                    :error-message="maxParticipantError(startMomentIndex)"
                                                    @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'maxParticipants', value }) }"
                                                />
                                            </div>

                                            <div class="row">
                                                <!-- Postcode -->
                                                <recess-input
                                                    :value="startMomentItem.postcode"
                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Postcode.Label"
                                                    :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Postcode.Placeholder"
                                                    :class="`col-4 qa-start-moment-postcode-${startMomentIndex}`"
                                                    @input="(newValue) => onStartMomentPostcodeTouch(startMomentIndex, newValue)"
                                                />


                                                <!-- City -->
                                                <recess-input
                                                    :value="startMomentItem.city"
                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.City.Label"
                                                    :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.City.Placeholder"
                                                    :error-message="startMomentCityError(startMomentIndex)"
                                                    :class="`col-4 qa-start-moment-city-${startMomentIndex}`"
                                                    @input="(newValue) => onStartMomentCityTouch(startMomentIndex, newValue)"
                                                />

                                                <!-- ID -->
                                                <recess-input
                                                    :value="startMomentItem.externalId"
                                                    :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ExternalId.Label"
                                                    :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ExternalId.Placeholder"
                                                    :class="`col-4 qa-start-moment-id-${startMomentIndex}`"
                                                    :error-message="startMomentExternalIdError(startMomentIndex)"
                                                    @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'externalId', value }) }"
                                                >
                                                    <template slot="customIcon">
                                                        <recess-tooltip>
                                                            <template slot="content">
                                                                <div>{{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ExternalId.Tooltip }}</div>
                                                            </template>
                                                        </recess-tooltip>
                                                    </template>
                                                </recess-input>
                                            </div>

                                            <div class="row">
                                                <div class="col-4 d-flex">
                                                    <recess-date-picker
                                                        :value="startMomentItem.startDate"
                                                        :default-value="startMomentItem.startDate"
                                                        :class="`w-60 qa-start-moment-start-date-${startMomentIndex}`"
                                                        :label-text="`${PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Date.Label}*`"
                                                        :error-message="startMomentFullDateError(startMomentIndex)"
                                                        date-picker-input-custom-width="170px"
                                                        @input="(newValue) => onStartMomentDateChanged(startMomentIndex, newValue)"
                                                    />

                                                    <!-- Type -->
                                                    <recess-multi-select
                                                        :selected-options="startMomentItem.type"
                                                        :options="startMomentTypes"
                                                        :single-select="true"
                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Type.Label"
                                                        :select-option-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Type.SelectText"
                                                        :class="`w-40 qa-start-moment-type-${startMomentIndex}`"
                                                        @input="(value) => { $emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'type', value }) }"
                                                    />
                                                </div>
                                            </div>

                                            <div v-if="enableCutOffDate"
                                                class="row"
                                            >
                                                <div class="col-6">
                                                    <recess-date-picker
                                                        :value="startMomentItem.cutOffDate"
                                                        :default-value="startMomentItem.cutOffDate"
                                                        :class="`qa-start-moment-cut-off-date-${startMomentIndex}`"
                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.CutOffDate.Label"
                                                        date-picker-input-custom-width="170px"
                                                        :error-message="startMomentCutOffDateError(startMomentIndex)"
                                                        @input="(newValue) => triggerCutOffDate(startMomentIndex, newValue)"
                                                    >
                                                        <div slot="customIcon">
                                                            <recess-tooltip class="ml-1">
                                                                <div slot="content">
                                                                    <div>
                                                                        {{ PROVIDER.Portfolio.CourseCreate.Form.Startmoments.CutOffDate.Tooltip }}
                                                                    </div>
                                                                </div>
                                                            </recess-tooltip>
                                                        </div>
                                                    </recess-date-picker>
                                                </div>
                                            </div>

                                            <!-- Modules -->

                                            <div class="row mt-5">
                                                <div v-for="(moduleItem, moduleIndex) in planningAndInvoicesData.learningMethod.planning.startMoments[startMomentIndex].modules"
                                                    :key="`moduleItem-${moduleIndex}`"
                                                    class="col-12 mb-2"
                                                >
                                                    <div class="row">
                                                        <div class="col-12 d-flex align-items-start justify-content-between">
                                                            <div class="row my-0 w-100">
                                                                <div class="col-4">
                                                                    <!-- Name -->
                                                                    <recess-input
                                                                        :value="moduleItem.name"
                                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.Name.Label"
                                                                        :error-message="moduleNameError(startMomentIndex, moduleIndex)"
                                                                        :class="`qa-module-name-S${startMomentIndex}-M${moduleIndex}`"
                                                                        @input="(newValue) => onModuleNameInput(startMomentIndex, moduleIndex, newValue)"
                                                                    />
                                                                </div>

                                                                <div v-if="!isOnCourseCreatePage"
                                                                    class="col-4"
                                                                >
                                                                    <recess-input
                                                                        :value="moduleItem.city"
                                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.City.Label"
                                                                        :class="`qa-module-city-S${startMomentIndex}-M${moduleIndex}`"
                                                                        @input="(value) => { $emit('setStartMoments', { action: 'setModuleField', startMomentIndex, moduleIndex, field: 'city', value }) }"
                                                                    />
                                                                </div>

                                                                <div v-if="!isOnCourseCreatePage"
                                                                    class="col-4"
                                                                >
                                                                    <!-- Day of week -->
                                                                    <recess-multi-select
                                                                        :selected-options="moduleItem.dayOfWeek"
                                                                        :options="daysOfWeek"
                                                                        :single-select="true"
                                                                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.DayOfWeek.Label"
                                                                        :select-options-text="daysOfWeek && daysOfWeek[0].displayText"
                                                                        :class="`qa-module-day-S${startMomentIndex}-M${moduleIndex}`"
                                                                        @input="(value) => { $emit('setStartMoments', { action: 'setModuleField', startMomentIndex, moduleIndex, field: 'dayOfWeek', value }) }"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="d-flex align-items-end s-delete-icon__input-align">
                                                                <span
                                                                    :class="`c-icon c-icon__bin qa-remove-module-button-S${startMomentIndex}-M${moduleIndex}`"
                                                                    @click="removeModule(startMomentIndex, moduleIndex)"
                                                                ></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- render events -->
                                                    <div class="row justify-content-between">
                                                        <div v-for="(eventItem, eventIndex) in moduleItem.events"
                                                            :key="`eventItem-${eventIndex}`"
                                                            class="mt-4 col-12 w-100 d-flex align-items-start"
                                                        >
                                                            <!-- Date & DayOfWeek-->
                                                            <recess-date-picker
                                                                :value="eventItem.eventDate"
                                                                :default-value="eventItem.eventDate"
                                                                :class="`mr-3 qa-event-date-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                :label-text="`${PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.EventDate.Label}*`"
                                                                :error-message="eventDateError(startMomentIndex, moduleIndex, eventIndex)"
                                                                date-picker-input-custom-width="170px"
                                                                @input="(newValue) => onEventDateChanged(startMomentIndex, moduleIndex, eventIndex, newValue)"
                                                            />
                                                            <!-- StartTime -->
                                                            <recess-input
                                                                :value="eventItem.startTime"
                                                                type="time"
                                                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.StartTime.Label"
                                                                :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.StartTime.Placeholder"
                                                                :class="`mr-3 qa-event-start-time-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'startTime', value }) }"
                                                            />

                                                            <!-- EndTime -->
                                                            <recess-input
                                                                :value="eventItem.endTime"
                                                                type="time"
                                                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.EndTime.Label"
                                                                :placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.EndTime.Placeholder"
                                                                :class="`mr-3 qa-event-end-time-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'endTime', value }) }"
                                                            />

                                                            <!-- Location -->
                                                            <recess-input
                                                                :value="eventItem.city"
                                                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.City.Label"
                                                                :class="`mr-3 qa-event-city-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'city', value }) }"
                                                            />

                                                            <!-- Buliding -->
                                                            <recess-input
                                                                :value="eventItem.building"
                                                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.Building.Label"
                                                                :class="`mr-3 qa-event-building-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'building', value }) }"
                                                            />

                                                            <!-- Room -->
                                                            <recess-input
                                                                :value="eventItem.room"
                                                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.Room.Label"
                                                                :class="`qa-event-room-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                @input="(value) => { $emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'room', value }) }"
                                                            />

                                                            <!-- remove event button -->
                                                            <div class="d-flex align-items-end s-delete-icon__input-align ml-5">
                                                                <span
                                                                    :class="`mr-4 s-duplicate-top c-icon c-icon__copy qa-duplicate-event-button-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                    @click="duplicateEvent(startMomentIndex, moduleIndex, eventItem)"
                                                                ></span>

                                                                <span
                                                                    :class="`c-icon c-icon__bin qa-remove-event-button-S${startMomentIndex}-M${moduleIndex}-E${eventIndex}`"
                                                                    @click="removeEvent(startMomentIndex, moduleIndex, eventIndex)"
                                                                ></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row my-0">
                                                        <div class="col-12 d-flex justify-content-end">
                                                            <!-- add event button -->
                                                            <recess-button
                                                                :title="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.AddEventItemButton"
                                                                icon="add"
                                                                variant="variant4"
                                                                :class="`qa-add-event-button-${moduleIndex}`"
                                                                @click.native.prevent="addEventItem(startMomentIndex, moduleIndex)"
                                                            />
                                                        </div>
                                                    </div>

                                                    <recess-divider show-line class="mt-2" />
                                                </div>

                                                <div class="col-12 d-flex justify-content-end">
                                                    <recess-button
                                                        :title="PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.AddModuleItemButton"
                                                        icon="add"
                                                        variant="variant4"
                                                        :class="`qa-add-module-button1-S${startMomentIndex}`"
                                                        @click.native.prevent="addModule(startMomentIndex)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </recess-accordion>
                            </div>
                        </div>
                    </template>
                    <!-- If no future start moments exist or e-learning course, show message -->
                    <template v-else>
                        <div class="w-100 d-flex justify-content-center align-items-center my-2">
                            <p>
                                {{ showNoCoursePlaceholderText }}
                            </p>
                        </div>
                    </template>
                </div>

            </div>
        </div>

        <div class="row">
            <!-- Notification, add start moment & submit button -->

            <div class="col-12">
                <recess-divider show-line />
            </div>

            <div class="col-12 mt-3 d-flex justify-content-end align-items-center">
                <button-component
                    type="button"
                    :back-label="PROVIDER.Portfolio.CourseEdit.PlanningTab.AddStartmomentButton"
                    icon-font="add"
                    button-class="c-button--variant2 mr-3 qa-add-start-moment-button"
                    @click.native.prevent="addStartMoment"
                />

                <recess-button
                    v-if="!isOnCourseEditPage"
                    variant="secondary"
                    :title="BUTTON_TEXT.previousTab"
                    class="qa-previous-tab-button mr-3"
                    @click.prevent.native="handleClickOnTab(null, 'previous-tab')"
                />

                <recess-button
                    variant="secondary"
                    :title="!isOnCourseEditPage ? BUTTON_TEXT.nextTab : BUTTON_TEXT.save"
                    class="qa-edit-course-submit-button"
                    :disabled="!startMomentItems"
                    @click.prevent.native="tabButtonAction()"
                />
            </div>
        </div>
    </recess-card>
</template>

<script>
/* eslint-disable func-names */
import { required, requiredIf } from 'vuelidate/lib/validators'
import { isValidCity, isValidDate, isValidDateFormat, isDateInThePast, isValidMinYear } from '@/utils/validationHelpers'
import {
    isRequiredErrorMessage, isInvalidErrorMessage, dateRangeHasErrorMessage, yearMinValueMessage,
    hasError, isUniqueErrorMessage, atLeastOneSelectedError, mandatoryFieldsError, mandatoryFieldsOnDuplicateStartmomentError,
    isValidCityMessage, minParticipantsMinValue, maxParticipantsMinValue
} from '@/../../shared/constants/validationMessageHelper.js'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { getDayOfWeekFromDate, formatEventDateTimeToLocal } from '@/utils/dateTimeHelper'
import { getEnumList, getItemById } from '@/../../shared/api/SharedClient'
import { validationMixin } from 'vuelidate'
import ButtonComponent from '@/components/atoms/ButtonComponent'
import getLastArrayItem from '@/utils/courseSharedHelper'
import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import {sortStartMoments, setVatPercentageOptionsForSpecificInvoiceItems } from '@/utils/vuexFunctionHelper'
import { emptyStartMomentItemSchema, emptyEventItemSchema, emptyModuleItemSchema } from './courseEditHelpers'
import axios from 'axios'

const SubtitleText = () => import('@/components/atoms/SubtitleText')
const StaticAlert = () => import('@/components/atoms/StaticAlert')

import tabMixin from '../../../mixins/tabNavigationMixin'

export default {
    components: {
        SubtitleText,
        ButtonComponent,
        StaticAlert
    },
    mixins: [validationMixin, tabMixin],
    props: {
        planningAndInvoicesData: {
            type: Object,
            required: false,
            default: () => ({
                source: null,
                productType: null,
                learningMethod: null
            })
        },
        startMomentsListOptions: {
            type: Object
        }
	},
    validations: {
        selectedFilters: {
            startDate: {
                isNotInThePast(value) {
                    if (value && isValidDate(value)) {
                        return !isDateInThePast(value)
                    }
                    return true
                },
                isValidDate,
                isValidDateFormat
            }
        },
        startMomentItems: {
            $each: {
                cutOffDate: {
                    isValidDate,
                    isBeforeStartDate(cutOffDateString, item) {
                        if (!item.inactive) {
                            return isDateInThePast(cutOffDateString, item.startDate)
                        }

                        // If the start moment is inactive, do not validate the cut-off date
                        return true
                    }
                },
                externalId: {
                    isUnique(value) {
                        const startMoments = this.startMomentItems.filter(startMoment => {
                            return startMoment.externalId === value && startMoment.externalId !== ''
                        })
                        return startMoments.length < 2
                    }
                },
                city: {
                    required,
                    isValidCity
                },
                startDate: {
                    required,
                    isValidDate,
                    isValidDateFormat,
                    minYear(_, startDate) {
                        if (!startDate.year) return true

                        return Number(startDate.year) > 1899
                    }
                },
                minParticipants: {
                    required: requiredIf(function (value) {
                        return value.cutOffDate !== null && value.cutOffDate !== ''
                    }),
                    minValue(value, item) {
                        // if min participants isnt defined return true since this field isnt mandatory
                        if (!item.minParticipants) return true

                        // If max participants is defined the min participants value needs to be:
                        // -- bigger then 0
                        // -- smaller or equal to max number of participants
                        if (item.maxParticipants) {
                            return Number(value) > 0 && Number(value) <= Number(item.maxParticipants)
                        }

                        // If min participants is defined the value needs to be:
                        // -- bigger then 0
                        return Number(value) > 0
                    }
                },
                maxParticipants: {
                    minValue(value, item) {
                        // if max participants isnt defined return true since this field isnt mandatory
                        if (!item.maxParticipants) return true

                        // If max participants is defined it needs to be:
                        // -- bigger or equal to min number of participants
                        return Number(value) > 0 && Number(value) >= Number(item.minParticipants)
                    }
                },
                modules: {
                    $each: {
                        name: {
                            required
                        },
                        events: {
                            $each: {
                                eventDate: {
                                    required,
                                    isValidDate,
                                    isValidDateFormat,
                                    isValidMinYear
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    activated() {
        this.getList({currentPage: this.setPaginationOptions.currentPage, itemsPerPage:  this.setPaginationOptions.itemsPerPageFromUrlQuery})
    },
    data() {
        return {
            PROVIDER,
            isValidModuleAccordion: false,
            isValidEventAccordion: false,
            BUTTON_TEXT,
            startMomentStatusOptions: [
                {
                    displayText: PROVIDER.Portfolio.CourseEdit.PlanningTab.SelectStartMomentStatus.Active,
                    value: 'active'
                },
                {
                    displayText: PROVIDER.Portfolio.CourseEdit.PlanningTab.SelectStartMomentStatus.Expired,
                    value: 'expired'
                }
            ],
            startMomentTypes: null,
            participantsOptions: [],
            daysOfWeek: null,
            startmomentDetails: []
        }
    },

    computed: {
        getPlanningData() {
            return this.planningAndInvoicesData?.learningMethod?.planning !== null && this.planningAndInvoicesData?.learningMethod && this.planningAndInvoicesData?.learningMethod?.planning?.startMoments?.length > 0
        },
        isProductTypeIncompany() {
            return this.planningAndInvoicesData.productType === 'Incompany'
        },
        startMomentItems() {
            return this.planningAndInvoicesData?.learningMethod?.planning?.startMoments
        },
        isSelfPlannedCourse() {
            return this.planningAndInvoicesData && this.planningAndInvoicesData.learningMethod && this.planningAndInvoicesData.learningMethod.type === 'SelfPlanned'
        },
        showNoCoursePlaceholderText() {
            return this.isSelfPlannedCourse ? PROVIDER.Portfolio.CourseCreate.Form.Startmoments.NoElearningStartMoment : PROVIDER.Portfolio.CourseCreate.Form.Startmoments.NoStartMomentAvailable
        },
        isOnCourseCreatePage() {
            return this.$route.name === 'course-create'
        },
        enableCutOffDate() {
            return this.planningAndInvoicesData.productType === 'Incompany'
        },
        setPaginationOptions() {
            return this.startMomentsListOptions.setPaginationOptions
        },
        setTableOptions() {
            return this.startMomentsListOptions.setTableOptions
        },
        filter() {
            return this.startMomentsListOptions.filter
        },
        selectedFilters() {
            return this.startMomentsListOptions.selectedFilters
        },
        cityOptions() {
            return this.startMomentsListOptions.cityOptions
        },
        isOnCourseDuplicatePage() {
            return this.$route.name === 'course-duplicate'
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getDaysOfWeek()
            this.getStartMomentTypes()
            this.generateParticipantsOptions()
        })
    },
    methods: {
        getStartMomentType(typeInputValue) {
            const typeEnum = this.startMomentTypes && this.startMomentTypes.find((x) => x.value === `${typeInputValue}`)
            if (!typeEnum) return ''
            return `${typeEnum.displayText}`
        },
        setEventsAmount(startmoment, startmomentIndex) {
            // Startmoment is closed  - show the events property from BE
            if (startmoment.totalEvents && !startmoment.hasDetails) return startmoment.totalEvents

            let events = 0
            // Startmoment is open, or is a new startmoment - check if user is adding more events
            if (startmoment.hasDetails || (!startmoment.hasDetails && !startmoment.id)) {
                this.planningAndInvoicesData.learningMethod.planning.startMoments[startmomentIndex].modules.forEach((module) => {
                    events += module.events.length
                })

                return events
            }

            return 1
        },
        getMinParticipants(startMoment) {
            if (!startMoment.minParticipants) return null
            return `${startMoment.minParticipants}`
        },
        getMaxParticipants(startMoment) {
            if (!startMoment.maxParticipants) return null
            return `${startMoment.maxParticipants}`
        },
        async getStartMomentTypes() {
            try {
                const response = await getEnumList(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.enumerations, 'startMomentType', null, null, false)
                if (!response) return
                this.startMomentTypes = response
            } catch (error) {
                console.error('Something went wrong while retrieving start moment types enum list', error)
            }
        },
        async getStartmomentDetails(id, index) {
            let courseID = this.getCourseId;
            if (this.isOnCourseDuplicatePage && !this.getCourseId) {
                courseID = this.startMomentsListOptions.copyID
            }
            try {
                const response = await getItemById(
                    `${process.env.VUE_APP_PROVIDER_V2_API_URL}${API_CALL_URL_PATHS.courses}/`,
                    courseID,
                    `start-moments/${id}`,
                    null,
                    false
                )

                if (!response) return
                response.hasDetails = true

                this.startmomentDetails = response

                formatEventDateTimeToLocal([this.startmomentDetails])
                setVatPercentageOptionsForSpecificInvoiceItems([this.startmomentDetails])

                this.$emit('setStartMoments', { action: 'setStartmomentDetail', index, startmomentDetails: this.startmomentDetails })
            } catch (error) {
                console.error('Something went wrong while retrieving start moment details', error)
            }
        },
        async getDaysOfWeek() {
            try {
                const response = await getEnumList(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.enumerations, 'dayOfWeek', null, null, false)
                if (!response) return
                this.daysOfWeek = response
                this.dayOfWeekWithNoDaySelectedOptions()
            } catch (error) {
                console.error('Something went wrong while retrieving days of week enum list', error)
            }
        },
        updateEditedCourse(selectedFiltersForUpdate = null) {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.$emit('submitForm')

                // Wait for the calls to finish to actually update the list of startmoments
                axios.interceptors.response.use((response) => {

                    if (selectedFiltersForUpdate) {
                        if (selectedFiltersForUpdate.startMomentStatus != this.selectedFilters.startMomentStatus) {
                            return response
                        }
                        this.updateList({ selectedFiltersInput: selectedFiltersForUpdate })
                    } else {
                        this.updateList({ selectedFiltersInput: this.selectedFilters })
                    }
                    return response
                })

            } else {
                this.emitValidation()
            }
        },
        updateList({ selectedFiltersInput }) {
            if (!this.startMomentItems) return
            const startMomentItems = JSON.parse(JSON.stringify(this.startMomentItems))
            sortStartMoments(startMomentItems)

            this.$emit('setStartMoments', { action: 'all', startMomentItems })
        },
        addStartMoment() {
            this.$v.$touch()
            if (this.startMomentItems && this.startMomentItems.length > 0) {
                // show error message when user keep clicking on add content button without filling in content title
                const lastItem = getLastArrayItem(this.startMomentItems)
                if (!lastItem?.city) {
                    this.getNotificationError(mandatoryFieldsError)
                    return
                }
            }
            this.addStartMomentItem()
        },
        addStartMomentItem() {
            // only allow to add more than 1 row if user filled in at least one value
            const checkEmptyItems = this.startMomentItems?.filter((item) => item.city === null || item.city === '')
            // allow duplicating items which are not invalid but have invalid siblings
            if (checkEmptyItems.length > 0) return
            const emptyStartMoment = JSON.parse(JSON.stringify(emptyStartMomentItemSchema))

            this.$emit('setStartMoments', { action: 'add', emptyStartMoment })
        },
        dayOfWeekWithNoDaySelectedOptions() {
            const noDaySelectedOption = [
                {
                    displayText: 'Kies een dag',
                    value: ''
                }
            ]
            this.daysOfWeek = [...noDaySelectedOption, ...this.daysOfWeek]
        },
        getStartMomentType(typeInputValue) {
            const typeEnum = this.startMomentTypes && this.startMomentTypes.find((x) => x.value === `${typeInputValue}`)
            if (!typeEnum) return ''
            return `${typeEnum.displayText}`
        },
        generateParticipantsOptions() {
            // eslint-disable-next-line no-plusplus
            for (let counter = 1; counter <= 100; counter++) {
                const optionToInclude = {
                    displayText: `${counter}`,
                    value: `${counter}`
                }

                this.participantsOptions = [...this.participantsOptions, optionToInclude]
            }
        },
        highlightStartMomentWithErrors(startMomentIndex, hasStartMomentErrors, isDuplicating) {
            this.$refs.accordions.forEach((startMoment, refIndex) => {
                if (startMomentIndex >= 0) {
                    this.$emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'hasErrors', value: hasStartMomentErrors })
                }

                if (startMomentIndex === refIndex) {
                    if (startMoment.dActive === true && isDuplicating && !hasStartMomentErrors) {
                        startMoment.dActive = false
                    }
                } else {
                    startMoment.dActive = false
                }
            })
        },
        async toggleAccordion(startMomentIndex, startmoment, active) {
            this.$v.$touch()
            const hasStartMomentErrors = this.checkStartMomentErrors(startMomentIndex)
            this.highlightStartMomentWithErrors(startMomentIndex, hasStartMomentErrors)
            startmoment.isActive = active

            if (active && !startmoment.hasDetails && !this.isOnCourseCreatePage && startmoment.id) {
                await this.getStartmomentDetails(startmoment.id, startMomentIndex)
            }
        },
        toggleStartmomentState(index, inactive) {
            this.$emit('setStartMoments', { action: 'setField', index, field: 'inactive', value: inactive })

            const toastNotification = {
                type: 'success'
            }

            if (inactive === true) {
                toastNotification.message = PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Success.ToggleState.Disactivate
            } else {
                toastNotification.message = PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Success.ToggleState.Activate
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })


            this.updateEditedCourse()
        },
        triggerDuplicateStartmoment(startMomentIndex) {
            this.$v.$touch()
            let toastNotification = {
                type: null,
                message: ''
            }

            const isDuplicating = true

            const hasStartMomentErrors = this.checkStartMomentErrors(startMomentIndex)
            this.highlightStartMomentWithErrors(startMomentIndex, hasStartMomentErrors, isDuplicating)

            if (hasStartMomentErrors) {
                toastNotification = {
                    type: 'error',
                    message: mandatoryFieldsOnDuplicateStartmomentError
                }

                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })

                return
            }

            toastNotification = {
                type: 'success',
                message: PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Success.Duplicate
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })

            this.duplicateStartmoment(startMomentIndex)

            setTimeout(() => {
                this.scrollToTop()
            }, 600)
        },
        async duplicateStartmoment(startMomentIndex) {
            const startMoment = this.planningAndInvoicesData.learningMethod.planning.startMoments[startMomentIndex]

            // We need to call details because we have to duplicate the full startmoment
            if (!startMoment.hasDetails && startMoment.id) {
                await this.getStartmomentDetails(startMoment.id, startMomentIndex)
            }

            const duplicateStartMoment = JSON.parse(JSON.stringify(this.planningAndInvoicesData.learningMethod.planning.startMoments[startMomentIndex]))

            if (duplicateStartMoment.externalId !== '') {
                duplicateStartMoment.externalId = ''
            }

            duplicateStartMoment.isActive = true
            // Delete id so we can identify that a POST needs to happen to this startmoment
            delete duplicateStartMoment.id

            const checkEmptyItems = this.planningAndInvoicesData.learningMethod.planning.startMoments.filter(item => item.city === null || item.city === '')
            // allow duplicating items which are not invalid but have invalid siblings
            if (checkEmptyItems.length > 0 && (duplicateStartMoment.city === null || duplicateStartMoment.city === '')) return
            this.$emit('setStartMoments', { action: 'add', emptyStartMoment: duplicateStartMoment })
        },
        scrollToTop() {
            const lastAddedIndex = this.$refs.accordions[this.$refs.accordions.length - 1]
            const startmomentTopPosition = lastAddedIndex.$el.getBoundingClientRect().top
            const scroll = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
            const scrollToAddedStartmoment = startmomentTopPosition + scroll

            lastAddedIndex.$el.classList.add('is-added')

            window.scroll({
                top: scrollToAddedStartmoment - 20,
                left: 0,
                behavior: 'smooth'
            })

            setTimeout(() => {
                lastAddedIndex.$el.classList.remove('is-added')
            }, 5000)
        },
        removeStartMomentItem(index, id) {
            const defaultIndex = 0
            const items = this.planningAndInvoicesData.learningMethod.planning.startMoments
            if (items && items.length > defaultIndex) {
                this.$emit('setStartMoments', { action: 'remove', startMomentIndex: index, id })
            }
        },
        removeModule(startMomentIndex, moduleIndex) {
            if (this.startMomentItems[startMomentIndex].modules.length === 1) {
                const toastNotification = {
                    type: 'error',
                    message: atLeastOneSelectedError('module')
                }

                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })

                return
            }

            this.$v.$reset()
            this.$emit('setStartMoments', { action: 'removeModule', startMomentIndex, moduleIndex })
        },
        addModule(index) {
            const emptyModuleItem = JSON.parse(JSON.stringify({ ...emptyModuleItemSchema }))
            const checkEmptyItems = this.planningAndInvoicesData.learningMethod.planning.startMoments[index].modules.filter((item) => item.name === null || item.name === '')

            if (checkEmptyItems.length > 0 && (emptyModuleItem.name === null || emptyModuleItem.name === '')) return
            this.$emit('setStartMoments', { action: 'addModule', index, emptyModuleItem })
        },
        addEventItem(startMomentIndex, moduleIndex) {
            const emptyEventItem = JSON.parse(JSON.stringify(emptyEventItemSchema))

            const checkEmptyItems = this.planningAndInvoicesData.learningMethod.planning.startMoments[startMomentIndex].modules[moduleIndex].events.filter((item) => item.eventDate === null || item.eventDate === '')
            if (checkEmptyItems.length > 0 && (emptyEventItem.eventDate === null || emptyEventItem.eventDate === '')) return
            this.$emit('setStartMoments', { action: 'addEvent', startMomentIndex, moduleIndex, value: emptyEventItem })
        },
        duplicateEvent(startMomentIndex, moduleIndex, eventItem) {
            this.$v.$reset()
            const duplicatedEvent = JSON.parse(JSON.stringify(eventItem))

            const checkEmptyItems = this.planningAndInvoicesData.learningMethod.planning.startMoments[startMomentIndex].modules[moduleIndex].events.filter((item) => item.eventDate === null || item.eventDate === '')
            if (checkEmptyItems.length > 0 && (duplicatedEvent.eventDate === null || duplicatedEvent.eventDate === '')) return
            this.$emit('setStartMoments', { action: 'addEvent', startMomentIndex, moduleIndex, value: duplicatedEvent })
        },
        removeEvent(startMomentIndex, moduleIndex, eventIndex) {
            const eventsLength = this.planningAndInvoicesData.learningMethod.planning.startMoments[startMomentIndex].modules[moduleIndex].events.length
            if (eventsLength === 1) {
                const toastNotification = {
                    type: 'error',
                    message: atLeastOneSelectedError('evenement')
                }

                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })

                return
            }

            this.$v.$reset()
            if (eventsLength > 0) {
                this.$emit('setStartMoments', { action: 'removeEvent', startMomentIndex, moduleIndex, eventIndex })
            }
        },
        onStartMomentCityTouch(startMomentIndex, newValue) {
            this.$emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'city', value: newValue })
            this.$v.startMomentItems.$each[startMomentIndex].city.$touch()
        },
        onStartMomentStreetNameTouch(startMomentIndex, newValue) {
            this.$emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'streetName', value: newValue })
        },
        onStartMomentHouseNumberTouch(startMomentIndex, newValue) {
            this.$emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'houseNumber', value: newValue })
        },
        onStartMomentPostcodeTouch(startMomentIndex, newValue) {
            this.$emit('setStartMoments', { action: 'setField', index: startMomentIndex, field: 'postcode', value: newValue })
        },
        onStartMomentDateChanged(startMomentIndex, newValue) {
            this.$v.startMomentItems.$each[startMomentIndex].startDate.$touch()

            this.$emit('setStartMoments', { action: 'setStartDateField', index: startMomentIndex, value: newValue })
        },
        onModuleNameInput(startMomentIndex, moduleIndex, newValue) {
            this.$emit('setStartMoments', { action: 'setModuleField', startMomentIndex, moduleIndex, field: 'name', value: newValue })
            this.$v.startMomentItems.$each[startMomentIndex].modules.$each[moduleIndex].name.$touch()
        },
        onEventDateChanged(startMomentIndex, moduleIndex, eventIndex, newValue) {
            const formattedDayOfWeek = getDayOfWeekFromDate(newValue)

            this.$emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'eventDate', value: newValue })
            this.$emit('setStartMoments', { action: 'setEventField', startMomentIndex, moduleIndex, eventIndex, field: 'dayOfWeek', value: formattedDayOfWeek })

            this.$v.startMomentItems.$each[startMomentIndex].modules.$each[moduleIndex].events.$each[eventIndex].eventDate.$touch()
        },
        triggerCutOffDate(startmomentIndex, newValue) {
            let cutOffDate = newValue
            if (newValue === '') cutOffDate = null

            this.$v.startMomentItems.$each[startmomentIndex].cutOffDate.$touch()
            this.$v.startMomentItems.$each[startmomentIndex].minParticipants.$touch()
            this.$emit('setStartMoments', { action: 'setField', index: startmomentIndex, field: 'cutOffDate', value: cutOffDate })
        },
        checkStartMomentErrors(startMomentIndex) {
            return this.$v.startMomentItems.$each[startMomentIndex].$invalid
        },
        getNotificationError(errorMessage) {
            const toastNotification = {
                type: 'error',
                message: errorMessage
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })
        },
        minParticipantError(startMomentIndex) {
            if (!this.$v.startMomentItems.$each[startMomentIndex]) {
                return 
            }
            if (this.$v.startMomentItems.$each[startMomentIndex].minParticipants.$error && !this.$v.startMomentItems.$each[startMomentIndex].minParticipants.minValue) {
                return minParticipantsMinValue

            }

            return isRequiredErrorMessage('minParticipants', 'Min deelnemers', this.$v.startMomentItems.$each[startMomentIndex].minParticipants)
        },
        maxParticipantError(startMomentIndex) {
            if (!this.$v.startMomentItems.$each[startMomentIndex]) {
                return 
            }

            return this.$v.startMomentItems.$each[startMomentIndex].maxParticipants.$error && !this.$v.startMomentItems.$each[startMomentIndex].maxParticipants.minValue
                ? maxParticipantsMinValue
                : null
        },
        startMomentExternalIdError(startmomentIndex) {
            if (!this.$v.startMomentItems.$each[startmomentIndex] || !this.$v.startMomentItems.$each[startmomentIndex].externalId.$error) return null

            return isUniqueErrorMessage('Startmoment kenmerk', this.$v.startMomentItems.$each[startmomentIndex].externalId, 'isUnique')
        },
        startMomentFullDateError(startMomentIndex) {
            if (!this.$v.startMomentItems.$each[startMomentIndex] || !this.$v.startMomentItems.$each[startMomentIndex].startDate.$error) return null

            return (
                isRequiredErrorMessage('startDate', 'Datum', this.$v.startMomentItems.$each[startMomentIndex].startDate) ||
                isInvalidErrorMessage(PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Date.Label, this.$v.startMomentItems.$each[startMomentIndex].startDate, 'isValidDate') ||
                hasError(yearMinValueMessage, this.$v.startMomentItems.$each[startMomentIndex].startDate, 'minYear')
            )
        },
        startMomentCutOffDateError(startmomentIndex) {
            return (
                isInvalidErrorMessage(PROVIDER.Portfolio.CourseCreate.Form.Startmoments.CutOffDate.Label, this.$v.startMomentItems.$each[startmomentIndex].cutOffDate, 'isValidDate') ||
                dateRangeHasErrorMessage('maxDate', this.$v.startMomentItems.$each[startmomentIndex].cutOffDate, 'isBeforeStartDate', {
                    startDate: 'datum', endDate: 'startmoment'
                })
            )
        },
        moduleNameError(startMomentIndex, moduleIndex) {
            return isRequiredErrorMessage('moduleName', 'Naam', this.$v.startMomentItems.$each[startMomentIndex].modules.$each[moduleIndex]?.name)
        },
        startMomentCityError(startMomentIndex) {
            if (!this.$v.startMomentItems.$each[startMomentIndex] || !this.$v.startMomentItems.$each[startMomentIndex].city.$error) return null
            if (!this.$v.startMomentItems.$each[startMomentIndex].city.isValidCity) {
                return isValidCityMessage
            }

            return isRequiredErrorMessage('city', 'Regio/plaats', this.$v.startMomentItems.$each[startMomentIndex].city)
        },
        eventDateError(startMomentIndex, moduleIndex, eventIndex) {
            if (!this.$v.startMomentItems.$each[startMomentIndex] || !this.$v.startMomentItems.$each[startMomentIndex].modules.$each[moduleIndex]?.events.$each[eventIndex].eventDate.$error) return null

            return (
                isRequiredErrorMessage('eventDate', 'Datum', this.$v.startMomentItems.$each[startMomentIndex].modules.$each[moduleIndex].events.$each[eventIndex].eventDate) ||
                isInvalidErrorMessage(PROVIDER.Portfolio.CourseCreate.Form.Startmoments.ModuleItem.EventItem.EventDate.Label, this.$v.startMomentItems.$each[startMomentIndex].modules.$each[moduleIndex].events.$each[eventIndex].eventDate, 'isValidDate') ||
                hasError(yearMinValueMessage, this.$v.startMomentItems.$each[startMomentIndex].modules.$each[moduleIndex].events.$each[eventIndex].eventDate, 'isValidMinYear')
            )
        },
        changeStatus(status) {
            this.$emit('updateFilters', 'startMomentStatus', status)
        },
        changeStartDate(startDate) {
            this.$v.selectedFilters.startDate.$touch()

            if (!this.$v.selectedFilters.startDate.$error) {
                this.$emit('updateFilters', 'startDate', startDate)
            }
        },
        changeLocation(location) {
            this.$emit('updateFilters', 'city', location)
        },
        isStartDateInThePast() {
            this.$v.selectedFilters.startDate.$touch()
            if (this.$v.selectedFilters.startDate.$error) {
                if (!this.$v.selectedFilters.startDate.isNotInThePast) {
                    return 'Datum moet in de toekomst zijn'
                }
                if (!this.$v.selectedFilters.startDate.isValidDate) {
                    return isInvalidErrorMessage(PROVIDER.Portfolio.CourseCreate.Form.Startmoments.AccordionTitle, this.$v.selectedFilters.startDate.isValidDate, 'isValidDate')
                }
            }
        },
        getList({ currentPage, itemsPerPage }){
            if (!this.isOnCourseCreatePage) {
                this.$v.$touch()

                if (!this.$v.$invalid) {
                    this.$emit('updateStartmomentList', {currentPage, itemsPerPage}) 
                }
            }
        },
    } 
}
</script>

<style lang="scss">
.has-inner-error {
    box-shadow: 0 0 12px 0 RGBA(208, 0, 0, 0.4) !important;
}

.s-duplicate-top {
    top: -3px;
}

.is-added {
    box-shadow: 0 0 20px 0 RGBA(78, 205, 196, 1) !important;
}

.c-recess-input__label-wrapper {
    padding-right: 0;
}

.qa-startmoments-table.c-recess-table-flex {
    background-color: transparent;

    .c-recess-table-flex__body .c-recess-table-flex__row:not(.is-disabled):hover {
        box-shadow: none;
    }

    .c-recess-table-flex__body .c-recess-table-flex__row {
        border: none;
    }

    .c-recess-table-flex__cell {
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        word-break: normal;
    }

    .mt-3 {
        margin-top: 0 !important;
    }
}


</style>

